<template>
<span>
    <template>
        <v-layout row justify-end>
            <v-dialog v-model="dialog_foto" persistent max-width="600px">
                <v-card>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <img v-if="cont_ft_foto" :src="`${base_url}upload/content_photo/g/${cont_ft_foto}?${uuid}`" class="foto-ampliada" />
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey darken-1" @click="dialog_foto = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </template>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Imagens Conteúdo</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey darken-2" dark class="mb-1" @click="atualizarDados">
                <v-icon dark>sync</v-icon>Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'contents.foto', params: {cont_id: content_id}}">
                <v-icon class="mdi mdi-plus"></v-icon>Adicionar Nova imagem
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="loadContentPhotos" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.cont_ft_foto`]="{ item }">
                <td width="15%" v-if="item.cont_ft_foto"><img :src="`${base_url}upload/content_photo/p/${item.cont_ft_foto}?${uuid}`" class="avatar" @click.prevent="ampliarFoto(item.cont_ft_foto)"></td>
                <td width="15%" v-if="!item.cont_ft_foto"><img :src="`${base_url}upload/logo.png`" class="avatar"></td>
            </template>

            <template v-slot:[`item.cont_ft_capa`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.cont_ft_capa" @change="onChangeEventHandlerCapa(item.cont_ft_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.cont_ft_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.cont_ft_status" @change="onChangeEventHandlerStatus(item.cont_ft_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editContentPhotos(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteContentPhotos(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

                <v-tooltip top color="lime black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="lime lighten-1" @click.prevent="ampliarFoto(item.cont_ft_foto)" v-on="on">
                            <v-icon dark class="mdi mdi-image-search"></v-icon>
                        </v-btn>
                    </template>
                    <span>Ampliar Imagem</span>
                </v-tooltip>
            </template> <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>
        </v-data-table>
    </v-card>
</span>
</template>

<script>
import {
    uuid
} from "vue-uuid";
import {
    URL_BASE
} from "../../../config/configs";

export default {
    name: "ContentPhotoComponent",
    created() {
        this.$store.dispatch("loadContentPhotosByContent", this.content_id);
    },
    computed: {
        loadContentPhotos() {
            if (this.$store.state.contentphotos.fotos.data !== null)
                return this.$store.state.contentphotos.fotos;
        }
    },
    props: {
        cont_ft_id: {
            require: true
        },
        content_id: {
            require: true
        }
    },
    data() {
        return {
            dialog_foto: "",
            cont_ft_foto: null,
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    "10": -1
                }
            ],
            headers: [{
                    text: "Imagem",
                    align: "left",
                    sortable: false,
                    value: "cont_ft_foto"
                },
                {
                    text: "Titulo",
                    value: "cont_ft_titulo",
                    sortable: false
                },
                {
                    text: "Legenda",
                    value: "cont_ft_legenda",
                    sortable: false
                },
                {
                    text: "Fotógrafo",
                    value: "cont_ft_fotografo",
                    sortable: false
                },
                {
                    text: "Link",
                    value: "cont_ft_link",
                    sortable: false
                },
                {
                    text: "Código",
                    value: "cont_ft_codigo",
                    sortable: false
                },
                {
                    text: "Capa",
                    value: "cont_ft_capa",
                    sortable: false
                },
                {
                    text: "Status",
                    value: "cont_ft_status",
                    sortable: false
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            content: [],
            base_url: URL_BASE,
            uuid: uuid.v1()
        };
    },
    methods: {
        atualizarDados() {
            this.$store.dispatch("loadContentPhotosByContent", this.content_id);
        },
        editContentPhotos(item) {
            this.$router.push({
                name: "contentphotos.edit",
                params: {
                    cont_ft_id: item.cont_ft_id
                }
            });
        },
        deleteContentPhotos(item) {

            if (item.cont_ft_capa) {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: "Atenção!",
                    text: "Esta foto é capa, escolha outra como capa antes de excluí-la",
                    showConfirmButton: true,
                    timer: 6000
                });
            } else {
                this.$swal({
                    title: "Você tem certeza?",
                    text: "Você não poderá reverter isso",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#D32F2F",
                    cancelButtonColor: "#1976D2",
                    confirmButtonText: "Sim, Exclua!"
                }).then(result => {
                    if (result.value) {
                        this.$store
                            .dispatch("destroyContentPhoto", item.cont_ft_id)
                            .then(response => {
                                if (response) {
                                    this.atualizarDados();
                                } else {
                                    this.$swal({
                                        position: "center",
                                        icon: "error",
                                        title: "Opssss!",
                                        text: "Erro ao excluir dados",
                                        showConfirmButton: true,
                                        timer: 6000
                                    });
                                }
                            })
                            .catch(error => {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: "Opssss!",
                                    text: "Algo errado aconteceu!",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            });
                    }
                });
            }
        },
        onChangeEventHandlerStatus(cont_ft_id, value) {
            this.$store
                .dispatch("updateContentPhotoStatus", {
                    cont_ft_id: cont_ft_id,
                    cont_ft_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar status",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        onChangeEventHandlerCapa(item, value) {
            this.$store
                .dispatch("updateContentPhotoCapa", {
                    cont_ft_id: item.cont_ft_id,
                    content_id: item.content_id,
                    cont_ft_capa: value
                })
                .then(response => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Capa atualizada com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                    this.$store.dispatch("loadContentPhotosByContent", this.content_id);
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar Capa",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        ampliarFoto(foto) {
            this.cont_ft_foto = foto;
            this.dialog_foto = true;
        }
    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}

.foto-ampliada {
    max-width: 100%;
}
</style>
